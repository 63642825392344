
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import {
  AddUserTicket,
  TicketTypes,
} from "@/store/modules/ticket/ticket.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const ticketX = namespace("Ticket");

@Component({
  components: {
    DatePicker,
    ProductSelect: () => import("@/components/product/ProductSelect.vue"),
    CategorySelect: () => import("@/components/category/CategorySelect.vue"),
    UserSelect: () => import("@/components/user/UserSelect.vue"),
    PrioritySelect: () => import("@/components/priority/PrioritySelect.vue"),
    SiteSelect: () => import("@/components/site/SiteSelect.vue"),
  },
})
export default class AddTicket extends Mixins(ValidationMixin) {
  @ticketX.State(TicketTypes.ADD_TICKET_STATE)
  public addTicket!: DefaultState;

  @ticketX.State(TicketTypes.TICKET_STATUSES)
  public ticketStatuses!: string[];

  @ticketX.Mutation(TicketTypes.SET_ADD_TICKET_DIALOG)
  public setAddTicket!: (addTicket: boolean) => void;

  @ticketX.Action(TicketTypes.ADD_TICKET)
  public saveTicket!: (farmer: AddUserTicket) => Promise<void>;

  public addTicketStep = 1;

  public isValid = false;

  public ticket: AddUserTicket = {
    issue: "",
    ticketNumber: "",
    description: "",
    product: "",
    site: "",
    category: "",
    priority: "",
  };

  @Ref("form") public formRef!: Validator;

  closeAddTicketDialog(): void {
    this.setAddTicket(false);
  }

  async saveTicketToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveTicket(this.ticket);
    }
  }

  // mounted(): void {}
}
